import axios from "@axios"

export default {
  namespaced: true,
  state: {
    term: [],
  },
  getters: {},
  mutations: {
    SET_TERMS(state, term) {
      state.term = term
    },
    ADD_TERM(state, item){
      state.term.unshift(item)
    }

  },
  actions: {
    fetchLestTerm({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/admin/lestTerm', {
          params: args,
        }).then(({ data }) => {   
            commit('SET_TERMS', data)
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
    store({ commit }, data) {
      return new Promise((resolve, reject) => {
          axios.post('/admin/terms', {
              ...data
          }).then((response) => {
              commit('ADD_TERM', response.data.term)
              resolve(response)
          }).catch(err => {
              return reject(err)
          })
      })

    },
  },
}
