import axios from "@axios"

export default {
  namespaced: true,
  state: {
    plans: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {},
  mutations: {
    SET_PLANS(state, plans) {
      state.plans = plans
    },
    ADD_PLAN(state, item) {
        state.plans.unshift(item)
    },

    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }
  },
  actions: {
    fetchPlans({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/admin/plans', {
          params: args,
        }).then(({ data }) => {   
          if (args.pagination == false) {
            commit('SET_PLANS', data)
          }
          else  {
            commit('SET_PLANS', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
          
           
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/plans', {
                ...data
            }).then((response) => {
                commit('ADD_PLAN', response.data.plans)
                resolve(response)
            }).catch(err => {
                return reject(err)
            })
        })

      },
  },
}
