import router from "@/router";
import axios from "@axios";
import moment from "moment";

export default {
	namespaced: true,
	state: {
		users: [],
		me: [],
		totalPages: 0,
	},
	getters: {},
	mutations: {
		SET_BEARER(state, accessToken) {
			// localStorage.removeItem(["accessToken", "expiresIn", "refreshToken"]);
			axios.defaults.headers.Authorization = "Bearer " + accessToken;
		},
		SET_ME(state, me) {
      state.me = me
    },
	},
	actions: {
		login({ commit }, payload) {
			const requestBody = {
				email: payload.userDetails.email,
				password: payload.userDetails.password
			}
			return new Promise((resolve, reject) => {
				localStorage.removeItem([
					"accessToken",
					"expiresIn"
				]);
				
				axios.post("auth/login", requestBody)
					.then(response => {
						if (response.data.access_token) {
							console.log(response)
							localStorage.setItem(
								"accessToken",
								response.data.access_token
							);
							localStorage.setItem(
								"expiresIn",
								moment(
										new Date(
												Date.now() +
														(response.data.expires_in / 60) *
																60 *
																1000
										)
								).format("YYYY-MM-DDTHH:mm:ss")
							);
							
							commit("SET_BEARER", response.data.access_token);
						}
						if (response.data.user) {
							commit("UPDATE_USER_INFO", response.data.user, { root: true });
						}

						router.push(router.currentRoute.query.to || "/");

						resolve(response);
					}).catch(() => {
						reject({
							message: "Algo deu errado, por favor tente novamente."
						});
					});
			});
		},
		fetchMe({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/me', {
          params: args,
        }).then(({ data }) => {   
            commit('SET_ME', data.data)
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
	}
};
