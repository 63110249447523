import axios from "@axios"

export default {
  namespaced: true,
  state: {
    letters: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {},
  mutations: {
    SET_LETTER(state, letters) {
      state.letters = letters
    },

    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }
  },
  actions: {
    fetchLetter({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/admin/letters', {
          params: args,
        }).then(({ data }) => {   
          if (args.pagination == false) {
            commit('SET_LETTER', data)
          }
          else  {
            commit('SET_LETTER', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
  },
}
