import Vue from 'vue';
import Router from 'vue-router';
import { isSignedIn } from '@/auth'

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [

    // Precisa do token

    {
      path: '',
      component: () => import('@/layouts/Main'),
      beforeEnter(_, __, next) {
        if (isSignedIn()) {
          next();
          return;
        }
        next('/login')
      },
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import('@/views/Home.vue'),
          meta: {
            pageTitle: 'Dashboard',
            breadcrumb: [
              {
                text: 'Dashboard',
                active: true,
              },
            ],
          },
        },
    
        // Tenants
    
        {
          path: '/clientes',
          name: 'clients',
          component: () => import('@/views/tenants/Index.vue'),
          meta: {
            pageTitle: 'Clientes',
            breadcrumb: [
              {
                text: 'Clientes',
                active: true,
              },
            ],
          },
        },
    
        // Profile Tenants
        
        {
          path: '/clientes/:id/perfil',
          name: 'profile',
          component: () => import('@/views/tenants/Profile.vue'),
          meta: {
            pageTitle: 'Perfil',
            breadcrumb: [
              {
                text: 'Perfil',
                active: true,
              },
            ],
          },
        },
    
        // Client Tenants
        
        {
          path: '/clientes/:id/clientes',
          name: 'clientsTenants',
          component: () => import('@/views/tenants/ViewClient.vue'),
          meta: {
            pageTitle: 'Clientes',
            breadcrumb: [
              {
                text: 'Clientes',
                active: true,
              },
            ],
          },
        },
    
        // Plans
    
        {
          path: '/planos',
          name: 'plans',
          component: () => import('@/views/plans/Index.vue'),
          meta: {
            pageTitle: 'Planos',
            breadcrumb: [
              {
                text: 'Planos',
                active: true,
              },
            ],
          },
        },
    
        // Negotiation
    
        // Faturas - Invoices
    
        {
          path: '/faturas',
          name: 'invoices',
          component: () => import('@/views/negotiation/Invoices.vue'),
          meta: {
            pageTitle: 'Faturas',
            breadcrumb: [
              {
                text: 'Faturas',
                active: true,
              },
            ],
          },
        },
    
        // Ações - Action
    
        {
          path: '/acao',
          name: 'action',
          component: () => import('@/views/negotiation/Action.vue'),
          meta: {
            pageTitle: 'Histórico de ações',
            breadcrumb: [
              {
                text: 'Ação',
                active: true,
              },
            ],
          },
        },
    
        // Colaborador - Collaborator
    
        {
          path: '/colaborador',
          name: 'collaborator',
          component: () => import('@/views/negotiation/Collaborator.vue'),
          meta: {
            pageTitle: 'Envio por colaborador',
            breadcrumb: [
              {
                text: 'Colaborador',
                active: true,
              },
            ],
          },
        },
    
        // Cards
    
        {
          path: '/cartas',
          name: 'cards',
          component: () => import('@/views/cards/Index.vue'),
          meta: {
            pageTitle: 'Cartas',
            breadcrumb: [
              {
                text: 'Cartas',
                active: true,
              },
            ],
          },
        },
    
        // Titles
    
        {
          path: '/titulos',
          name: 'titles',
          component: () => import('@/views/titles/Index.vue'),
          meta: {
            pageTitle: 'Títulos',
            breadcrumb: [
              {
                text: 'Títulos',
                active: true,
              },
            ],
          },
        },
    
        // NegotiationTitles
    
        {
          path: '/negociacao',
          name: 'NegotiationTitles',
          component: () => import('@/views/modules/NegotiationTitles.vue'),
          meta: {
            pageTitle: 'Negociação',
            breadcrumb: [
              {
                text: 'Negociação',
                active: true,
              },
            ],
          },
        },
    
        // Installment
    
        {
          path: '/parcelamento',
          name: 'installment',
          component: () => import('@/views/modules/Installment.vue'),
          meta: {
            pageTitle: 'Parcelamento',
            breadcrumb: [
              {
                text: 'Parcelamento',
                active: true,
              },
            ],
          },
        },
    
        // Serasa
    
        {
          path: '/serasa',
          name: 'serasa',
          component: () => import('@/views/modules/Serasa.vue'),
          meta: {
            pageTitle: 'Serasa',
            breadcrumb: [
              {
                text: 'Serasa',
                active: true,
              },
            ],
          },
        },

        // terms
    
        {
          path: '/termos',
          name: 'terms',
          component: () => import('@/views/terms/Index.vue'),
          meta: {
            pageTitle: 'Termos',
            breadcrumb: [
              {
                text: 'Termos',
                active: true,
              },
            ],
          },
        },

      ]
    },

    // Não precisa do token

    {
      path: '',
      component: () => import('@/App.vue'),
      beforeEnter(_, __, next) {
        if (!isSignedIn()) {
          next();
          return;
        }
        next('/')
      },
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/auth/Login.vue'),
          meta: {
            layout: 'full',
          },
        },
        {
          path: '/error-404',
          name: 'error-404',
          component: () => import('@/views/error/Error404.vue'),
          meta: {
            layout: 'full',
          },
        },
        {
          path: '*',
          redirect: 'error-404',
        },

      ]
    }



  ],
})

router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = "none";
  }
})

export default router
