import axios from "@axios"

export default {
  namespaced: true,
  states: {
    cities: [],
  },
  getters: {},
  mutations: {
    SET_CITIES(state, cities) {
      state.cities = cities
    },

  },
  actions: {
    fetchCities({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`/admin/cities/${args.id}`, {
          params: args,
        }).then(({ data }) => {   
            commit('SET_CITIES', data)
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
  },
}
