import axios from "@axios"

export default {
  namespaced: true,
  state: {
    tenants: [],
    clients: [],
    users: [],
    tenant: {},
    consu_tenant: [],
    consu_tenant_yearly: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {},
  mutations: {
    SET_TENANTS(state, tenants) {
      state.tenants = tenants
    },
    SET_TENANT(state, tenant) {
      state.tenant = tenant
    },
    SET_CONSU_TENANT(state, consu) {
      state.consu_tenant = consu
    },
    SET_CONSU_TENANT_YEARLY(state, consus) {
      state.consu_tenant_yearly = consus
    },
    ADD_TENANT(state, item) {
      state.tenants.unshift(item)
    },
    ADD_CLIENT(state, item) {
      state.clients.unshift(item)
    },
    ADD_USER(state, item) {
      state.users.unshift(item)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }
  },
  actions: {
    fetchTenants({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/admin/tenants', {
          params: args,
        }).then(({ data }) => {      
          if (args.pagination == false) {
            commit('SET_TENANTS', data)
          }
          else  {
            commit('SET_TENANTS', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 

          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
    fetchTenant({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`admin/tenants/${args.tenant_id}`, {
          params: args,
        })
        .then(({ data }) => {
          commit('SET_TENANT', data)
          resolve(data)
        }).catch((error) => { reject(error) })
      })
    },
    fetchConsuTenant({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`admin/dashboard/consuTenant/${args.tenant_id}`, {
          params: args,
        })
        .then(({ data }) => {
          commit('SET_CONSU_TENANT', data)
          resolve(data)
        }).catch((error) => { reject(error) })
      })
    },
    fetchConsuTenantYearly({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`admin/dashboard/consuTenantYearly/${args.tenant_id}`, {
          params: args,
        })
        .then(({ data }) => {
          commit('SET_CONSU_TENANT_YEARLY', data)
          resolve(data)
        }).catch((error) => { reject(error) })
      })
    },
    store({ commit }, data) {
      return new Promise((resolve, reject) => {
          axios.post('/admin/tenants', {
              ...data
          }).then((response) => {
              commit('ADD_TENANTS', response.data.tenants)
              resolve(response)
          }).catch(err => {
              return reject(err)
          })
      })

    },
    storeClient({ commit }, data) {
      return new Promise((resolve, reject) => {
          axios.post('/admin/clients', {
              ...data
          }).then((response) => {
              commit('ADD_CLIENT', response.data.clients)
              resolve(response)
          }).catch(err => {
              return reject(err)
          })
      })

    },
    storeUser({ commit }, data) {
      return new Promise((resolve, reject) => {
          axios.post('/admin/users', {
              ...data
          }).then((response) => {
              commit('ADD_USER', response.data.users)
              resolve(response)
          }).catch(err => {
              return reject(err)
          })
      })

    },
  },
}
