import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import tenants from './tenants'
import plans from './plans'
import historics from './historics'
import dashboard from './dashboard'
import logsend from './logsend'
import negotiations from './negotiation'
import titles from './titles'
import letters from './letter'
import serasas from './serasas'
import installments from './installments'
import invoices from './invoices'
import states from './states'
import cities from './cities'
import auth from './auth'
import term from './terms'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    tenants,
    plans,
    historics,
    dashboard,
    logsend,
    negotiations,
    titles,
    letters,
    serasas,
    installments,
    invoices,
    states,
    cities,
    auth,
    term
  },
  strict: process.env.DEV,
})
