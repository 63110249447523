import axios from "@axios"

export default {
  namespaced: true,
  state: {
    installments: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {},
  mutations: {
    SET_INSTALLMENT(state, installments) {
      state.installments = installments
    },

    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }
  },
  actions: {
    fetchInstallment({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/admin/installments', {
          params: args,
        }).then(({ data }) => {   
          if (args.pagination == false) {
            commit('SET_INSTALLMENT', data)
          }
          else  {
            commit('SET_INSTALLMENT', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
          
           
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
  },
}
