import axios from "@axios"

export default {
  namespaced: true,
  state: {
    invoices: [],
    invoice: {},
    invoiceApp: {},
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {},
  mutations: {
    SET_INVOICES(state, invoices) {
      state.invoices = invoices
    },

    SET_INVOICE(state, invoice) {
      state.invoice = invoice
    },
    UPDATE_INVOICE(state, invoice) {
      const invoiceIndex = state.invoices.findIndex((p) => p.id == invoice.id)
      Object.assign(state.invoices[invoiceIndex], invoice)
    },

    SET_INVOICE_APP(state, invoiceApp) {
      state.invoiceApp = invoiceApp
    },

    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }
  },
  actions: {
    fetchInvoices({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/admin/invoices', {
          params: args,
        }).then(({ data }) => {   
            commit('SET_INVOICES', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })

          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchInvoice({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`admin/invoices/${args.invoice_id}`, {
          params: args,
        })
        .then(({ data }) => {
          commit('SET_INVOICE', data)
          resolve(data)
        }).catch((error) => { reject(error) })
      })
    },

    updateInvoice(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(`admin/invoices/${data.id}`, { ...data })
          .then((response) => {
            context.commit('UPDATE_INVOICE', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },

    fetchInvoiceApp({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`admin/invoicesApp/${args.pagesApp}`, {
          params: args,
        })
        .then(({ data }) => {
          commit('SET_INVOICE_APP', data)
          resolve(data)
        }).catch((error) => { reject(error) })
      })
    }

  },
}
