import axios from "@axios"

export default {
  namespaced: true,
  state: {
    dashboard: [],
    invoices: [],
    invoices_tenant: [],
    use_system: [],
    invoices_values: {},
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {},
  mutations: {
    SET_USABILITIES(state, dashboard) {
      state.dashboard = dashboard
    },
    SET_INVOICES(state, invoices) {
      state.invoices = invoices
    },
    SET_INVOICES_TENANT(state, invoices_tenant) {
      state.invoices_tenant = invoices_tenant
    },
    SET_INVOICES_VALUES(state, invoices_values) {
      state.invoices_values = invoices_values
    },
    SET_USE_SYSTEM(state, use_system){
      state.use_system = use_system
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }
  },
  actions: {
    fetchUsabilities({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/admin/dashboard/monthSummary', {
          params: args,
        }).then(({ data }) => {   
            commit('SET_USABILITIES', data)
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchInvoices({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/admin/dashboard/monthInvoices', {
          params: args,
        }).then(({ data }) => {   
            commit('SET_INVOICES', data)
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
    fetchInvoicesTenant({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/admin/dashboard/monthInvoicesTenant', {
          params: args,
        }).then(({ data }) => {   
            commit('SET_INVOICES_TENANT', data)
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchInvoicesValues({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/admin/dashboard/monthInvoicesValues', {
          params: args,
        }).then(({ data }) => {   
            commit('SET_INVOICES_VALUES', data)
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchUseSystem({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/admin/dashboard/useSystem', {
          params: args,
        }).then(({ data }) => {   
            commit('SET_USE_SYSTEM', data)
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

  },
}
