import Vue from 'vue'

// axios
import axios from 'axios'
// baseurl
const baseURL = process.env.VUE_APP_API_URL || "http://localhost:8000/api";

const axiosIns = axios.create({
  baseURL: baseURL,
})

axiosIns.interceptors.request.use(
  function (config) {
    const token = localStorage.accessToken
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosIns.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let errorCode = error.response.status;
    switch (errorCode) {
      case 401: {
        const errorMessage = error.response.data.message
        if (errorMessage == 'invalid_credentials') {
          return errorMessage;
        }
        if (errorMessage == 'Token Invalid' || errorMessage == 'User not found' || errorMessage ==
          'Authorization token not found') {
          // Vue.notify({
          //   title: "Erro",
          //   text: "Falha na autenticação. Redirecionado ao login!",
          //   type: "error"
          // });
          router.push("login")
        } else if (errorMessage == 'Token Expired') {
          // Vue.notify({
          //   title: "Erro",
          //   text: "Por favor, se autentique novamente.",
          //   type: "error"
          // });
          router.push("login")
        }
        break;
      }
      case 422: {
        let validation_messages = error.response.data.message;
        let messageFinal = "";
        for (var key in validation_messages) {
          var obj = validation_messages[key];
          for (var prop in obj) {
            messageFinal += obj[prop] + "\n";
          }
          // Vue.notify({
          //   title: "Erro de validação",
          //   text: messageFinal,
          //   type: "error",
          // });
          messageFinal = "";
        }
        break;
      }
      case 500: {
        // Vue.notify({
        //   title: "Erro",
        //   text: "Erro inesperado ): \n Por favor, tente novamente.",
        //   type: "error"
        // });
        break;
      }
      default: {
        // Vue.notify({
        //   title: "Erro",
        //   text: "Erro inesperado ): Por favor, tente novamente.",
        //   type: "error"
        // });
        break;
      }
    }

    return Promise.reject(error);
  }
);

Vue.prototype.$http = axiosIns

export default axiosIns
