import axios from "@axios"

export default {
  namespaced: true,
  states: {
    states: [],
  },
  getters: {},
  mutations: {
    SET_STATE(state, states) {
      state.states = states
    },

  },
  actions: {
    fetchStates({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/admin/states', {
          params: args,
        }).then(({ data }) => {   
            commit('SET_STATE', data)
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
  },
}
